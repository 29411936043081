import axios from "axios";
const url = "general/Api/Cities/"
export default {
    namespaced: true,
    state() {
        return {
            cities: [],
            totalCities: 0
        }
    },
    getters: {
        getCities(state) { return state.cities },
        getTotalCities(state) { return state.totalCities }
    },
    mutations: {
        SET_CITIES(state, cities) {
            state.cities = cities;
        },
        SET_TOTAL_CITIES(state, totalCities) {
            state.totalCities = totalCities;
        }
    },
    actions: {
        async get_allCities({ commit }, payload) {
            //console.log(payload)
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                commit('SET_CITIES', response.data.dataResult);
                commit('SET_TOTAL_CITIES', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting cities', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_cities(_,id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error get cities by ID', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async create_cities({rootGetters}, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    //Name, StateId, CreatedBy, IsActive
                    Name: payload.name,
                    StateId: payload.stateId,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating cities', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_cities({rootGetters}, cities) {
            try {
                const response = await axios.put(`${url}Update`, {
                    // Id, Name, StateId, ModifiedBy, IsActive
                    Id: cities.id,
                    Name: cities.name,
                    StateId: cities.stateId,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                
                return response.data;     
            } catch (error) {
                this._vm.$bvToast.toast('Error update cities', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        }
    }
}