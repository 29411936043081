import Vue from "vue";
import Router from "vue-router";
import { jwtDecode } from "jwt-decode";

Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,

	routes: [
		{
			path: "/",
			redirect: "/login",
			component: () => import("@/layouts/full-layout/FullLayout"),
			children: [
				{
					name: "Home",
					path: "/home",
					component: () => import("@/views/Home"),
					meta: {
						requiresAuth: true,
						requiredPermissions: [],
					},
				},
				{
					name: "Dashboard",
					path: "/dashboard",
					component: () => import("@/views/dashboard/Dashboard"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Dashboards"],
					},
				},
				{
					name: "Customers",
					path: "customers",
					component: () => import("@/views/customers/List"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Profiles-GetAll"],
					},
				},
				{
					name: "Customers To Builders",
					path: "/customers/list-to-builders",
					component: () => import("@/views/customers/ListToBuilders"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Profiles-GetAllToPartners"],
					},
				},
				{
					name: "Customer",
					path: "customers/detail/:customer",
					component: () => import("@/views/customers/Detail"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Profiles-GetById"],
					},
				},
				{
					path: "/security",
					name: "Security",
					redirect: "/security/users",
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Users-GetAll"],
					},
				},
				{
					name: "Users",
					path: "/security/users",
					component: () => import("@/views/security/Users"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Users-GetAll"],
					},
				},
				{
					name: "Roles",
					path: "/security/roles",
					component: () => import("@/views/security/Roles"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Roles-GetAll"],
					},
				},
				{
					name: "Applications",
					path: "/loans/applications",
					component: () => import("@/views/loans/Applications"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/LoanApplications-GetAll"],
					},
				},
				{
					name: "Active Loans",
					path: "/loans/active-loans",
					component: () => import("@/views/loans/CreatedLoans"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/LoanApplications-GetAll"],
					},
				},
				{
					name: "Active Loan Detail",
					path: "/loans/active-loans/:loan",
					component: () => import("@/views/loans/CreatedLoanDetail"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/LoanApplications-GetAll"],
					},
				},

				{
					name: "New Loan",
					path: "/loans/new",
					component: () => import("@/views/loans/NewLoan"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/LoanApplications-GetAll"],
					},
				},
				{
					name: "Loan Application",
					path: "/loans/applications/:customer",
					component: () => import("@/views/loans/ApplicationDetail"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/LoanApplications-GetAll"],
					},
				},
				{
					name: "Banks",
					path: "/banks-screening/list",
					component: () => import("@/views/loans/Banks"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Banks-GetAll"],
					},
				},
				{
					name: "Rules",
					path: "/loans/rules",
					component: () => import("@/views/loans/Rules"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Rules-GetAll"],
					},
				},
				{
					name: "Types",
					path: "/loans/types",
					component: () => import("@/views/loans/Types"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/LoanTypes-GetAll"],
					},
				},
				{
					name: "Partners",
					path: "/loans-affiliates/list",
					component: () => import("@/views/loans/Builders"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Profiles-GetAllToPartners"],
					},
				},
				{
					name: "Calculator",
					path: "/loans-affiliates/calculator",
					component: () => import("@/views/loans/New"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Profiles-GetAllToPartners"],
					},
				},
				{
					name: "Banks",
					path: "/general/banks",
					component: () => import("@/views/general/Banks"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Banks-GetAll"],
					},
				},
				{
					name: "Cities",
					path: "/general/cities",
					component: () => import("@/views/general/Cities"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Cities-GetAll"],
					},
				},
				{
					name: "Countries",
					path: "/general/countries",
					component: () => import("@/views/general/Countries"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/Countries-GetAll"],
					},
				},
				{
					name: "Reasons Types",
					path: "/general/customerReasonsTypes",
					component: () => import("@/views/general/CustomerReasonsTypes"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/CustomerTypes-GetAll"],
					},
				},
				{
					name: "States",
					path: "/general/states",
					component: () => import("@/views/general/States"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/States-GetAll"],
					},
				},
				{
					//CustomersDocumentsTypes
					name: "Documents Types",
					path: "/customers/documentsTypes",
					component: () => import("@/views/customers/DocumentsTypes"),
					meta: {
						requiresAuth: true,
						requiredPermissions: ["/CustomerDocumentTypes-GetAll"],
					},
				},
			],
		},

		{
			path: "*",
			component: () => import("@/views/authentication/Error"),
		},
		{
			path: "/access-denied",
			component: () => import("@/views/access-denied/AccessDenied"),
		},
		{
			path: "/login",
			component: () => import("@/views/authentication/Login"),
		},
		{
			path: "/screening-verification/:guid",
			component: () => import("@/views/screening-verification/ScreeningVerification"),
		},
		{
			path: "/email-verification/:id",
			component: () => import("@/views/email-verification/EmailVerification"),
		},
		{
			path: "/recovery-password/:id",
			component: () => import("@/views/recovery-password/RecoveryPassword"),
		},
	],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
	const token = localStorage.getItem("token");
	if (to.matched.some((route) => route.meta.requiresAuth) && !token) {
		next("/login");
	} else if (to.path === "/login" && token) {
		next("/home");
	}
	// Verificar permisos de acceso
	if (to.meta.requiredPermissions) {
		const user = jwtDecode(token);
		const session = JSON.parse(user.Session);
		const permissions = session.Authorizations; // Implementa esta función para obtener permisos del token
		const requiredPermissions = to.meta.requiredPermissions;
		const hasPermission = requiredPermissions.every((permission) => permissions.includes(permission));

		if (!hasPermission) {
			return next("/access-denied");
		}
	}
	next();
});

router.afterEach(() => {
	// Complete the animation of the route progress bar.
	NProgress.done();
});

export default router;
