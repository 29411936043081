import axios from "axios";
const url = "loans/Api/LoanApplications/";
const urlCompany = "loans/Api/CompanyLoansApplications/";

export default {
	namespaced: true,
	state() {
		return {
			applications: [],
			totalApplications: 0,
		};
	},
	getters: {
		getApplications(state) {
			return state.applications;
		},
		getTotalApplications(state) {
			return state.totalApplications;
		},
	},
	mutations: {
		SET_APPLICATIONS(state, applications) {
			state.applications = applications;
		},
		SET_TOTAL_APPLICATIONS(state, totalApplications) {
			state.totalApplications = totalApplications;
		},
	},
	actions: {
		async get_dashboardInformation() {
			//console.log(payload)
			try {
				const response = await axios.get(`${url}GetDashboardInformation`);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting applications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_allApplications({ commit }, payload) {
			//console.log(payload)
			try {
				let path = `${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`;
				if (payload.slug) path = path + `&Slug=${payload.slug}`;
				const response = await axios.get(path);
				commit("SET_APPLICATIONS", response.data.dataResult);
				commit("SET_TOTAL_APPLICATIONS", response.data.dataRecords);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting applications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_loanById(_, id) {
			try {
				const response = await axios.get(`${url}GetById/${id}`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting loan", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_loanOfficer(_, payload) {
			//console.log(payload)
			try {
				let path = `${url}UpdateLoanOfficer?LoanApplicationId=${payload.loanId}&AgentId=${payload.agentId}`;
				const response = await axios.post(path);
				this._vm.$bvToast.toast("Officer updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating loan officer", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_allBuilders({ rootGetters }, payload) {
			try {
				const response = await axios.get(
					`${urlCompany}/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&CompanyId=${rootGetters["auth/getUser"].CompanyId}`
				);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting applications", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_companyInformation({ rootGetters }) {
			try {
				const response = await axios.get(`${urlCompany}GetCompanyInformation/${rootGetters["auth/getUser"].CompanyId}`);
				return response.data.dataResult;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting company information", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_monthsByLoanType({ rootGetters }, payload) {
			console.log(rootGetters["auth/getUser"].Id);
			try {
				const response = await axios.get(
					`${urlCompany}GetMonthsByLoanType?Amount=${payload.amount}&LoanTypeId=1&CompanyId=${rootGetters["auth/getUser"].CompanyId}`
				);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting months", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
		async get_monthlyPayment({ rootGetters }, payload) {
			//console.log(payload)
			try {
				const response = await axios.get(
					`${urlCompany}GetMonthtlyPayment?Amount=${payload.amount}&Months=${payload.months}&LoanTypeId=1&CompanyId=${rootGetters["auth/getUser"].CompanyId}`
				);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting monthly payment", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_companyLoanRange({ rootGetters }) {
			console.log(rootGetters["auth/getUser"].Id);
			try {
				const response = await axios.get(`${urlCompany}GetLoanRange?LoanTypeId=1&CompanyId=${rootGetters["auth/getUser"].CompanyId}`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting months", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async get_loanRange({ rootGetters }, id) {
			console.log(rootGetters["auth/getUser"].Id);
			try {
				const response = await axios.get(`${url}GetLoanRange?LoanTypeId=${id}`);
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error getting months", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async add_builders({ rootGetters }, applicationData) {
			console.log(applicationData);
			try {
				const formData = new FormData();
				formData.append("LoanTypeId", 1);
				formData.append("Amount", applicationData.amount);
				formData.append("MonthPeriod", applicationData.periodInMonths);
				formData.append("MonthPayment", applicationData.monthlyPayment);
				formData.append("FirstName", applicationData.firstName);
				formData.append("LastName", applicationData.lastName);
				formData.append("Email", applicationData.email);
				formData.append("Telephone", applicationData.phone);
				formData.append("Address", applicationData.address);
				formData.append("Employeer", applicationData.employeerName);
				formData.append("DocumentId", applicationData.id);
				formData.append("Payslip", applicationData.payslip1);
				formData.append("Others", applicationData.other);
				formData.append("Quote", applicationData.quote);
				formData.append("CompanyId", rootGetters["auth/getUser"].CompanyId);

				console.log(formData);
				const response = await axios.post(`${urlCompany}Add`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				this._vm.$bvToast.toast("Loan request has been sent to Total.", {
					title: "Upload complete.",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error saving data", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},

		async update_typeSignature({ rootGetters }, payload) {
			try {
				let path = `${url}UpdateIsDigitalSignature?LoanApplicationId=${payload.id}&isDigitalSignature=${payload.isDigitalSignature}&ModifiedBy=${rootGetters["auth/getUser"].Id}`;
				const response = await axios.post(path);
				this._vm.$bvToast.toast("Type of signature updated successfully", {
					title: "Success",
					variant: "success",
					solid: true,
				});
				return response.data;
			} catch (error) {
				this._vm.$bvToast.toast("Error updating signature", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
			}
		},
	},
};
