import axios from "axios";
const url = "loans/Api/LoanTypes/"

export default {
    namespaced: true,
    state() {
        return {
            types: [],
            totalTypes: 0
        }
    },
    getters: {
        getTypes(state) { return state.types },
        getTotalTypes(state) { return state.totalTypes }
    },
    mutations: {
        SET_TYPES(state, types) {
            state.types = types;
        },
        SET_TOTAL_TYPES(state, totalTypes) {
            state.totalTypes = totalTypes;
        }
    },
    actions: {
        async get_allTypes({ commit }, payload) {
            try {
                const response = await axios.get(`${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}`);
                commit('SET_TYPES', response.data.dataResult);
                commit('SET_TOTAL_TYPES', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting types', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async get_types(_,id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error get types by ID', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async create_loanType({rootGetters}, payload) {
            try {
                const response = await axios.post(`${url}Add`, {
                    //Description, RouteService, CreatedBy, IsActive
                    Description: payload.description,
                    RouteService: payload.routeService,
                    CreatedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating types', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_types({rootGetters}, type) {
            try {
                const response = await axios.put(`${url}Update`, {
                    // Id, Description, RouteService, ModifiedBy, IsActive
                    Id: type.id,
                    Description: type.description,
                    RouteService: type.routeService,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: true
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error update types', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        async update_typeStatus({rootGetters}, type) {
            //Id, ModifiedBy, IsActive
            try {
                const response = await axios.put(`${url}UpdateStatus`, {
                    Id: type.id,
                    Description: type.description,
                    RouteService: type.routeService,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                    IsActive: type.isActive
                });
                this._vm.$bvToast.toast('Loan type status updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true
                })
                return response.data;
            } catch (error) {
                console.log(error);
                this._vm.$bvToast.toast('Error updating loan type status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
    }
}